<template>
  <multiselect
    :value="value"
    id="category"
    track-by="id"
    label="name"
    placeholder="Selecionar"
    :options="categories"
    :allow-empty="false"
    :option-height="40"
    :showLabels="false"
    :showNoResults="false"
    class="with-border"
    :disabled="disabled"
    @select="el => (this.value === el.id)"
    @input="(value) => $emit('input', value)"
  >
    <template slot="caret">
      <div class="chevron">
        <ChevronDown />
      </div>
    </template>
    <template slot="singleLabel" slot-scope="{ option }">
      {{ option.name }}
    </template>

    <template slot="noOptions"> Nenhuma opção </template>

    <template slot="noResult"> Nenhum resultado </template>
  </multiselect>
</template>
<script>
export default {
  components: { ChevronDown: () => import('@/assets/icons/chevron-down.svg') },
  props: ['value', 'disabled', 'categories'],
  methods: {}
}
</script>
